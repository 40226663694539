import LocaleWrap from "./wrapPageElement"

function initGTMOnEvent(event) {
  initGTM()
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent) // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false
  }

  window.gtmDidInit = true // flag to ensure script does not get added to DOM more than once.

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-57VJBQ8`

  script.onload = function () {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      // ensure PageViews are always tracked       
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
      "gtm.uniqueEventId": 0,
    })
  }

  document.head.appendChild(script)
}

export const onClientEntry = function () {
  document.onreadystatechange = function () {
    if (document.readyState !== "loading") setTimeout(initGTM, 1000)
  }

  document.addEventListener("scroll", initGTMOnEvent)
  document.addEventListener("mousemove", initGTMOnEvent)
  document.addEventListener("touchstart", initGTMOnEvent)
}

export const wrapPageElement = LocaleWrap


export const onRouteUpdate = (
  { location },
) => {
  const domElem = document.querySelector(`link[rel='canonical']`)
  const existingValue = domElem.getAttribute(`href`)
  const baseProtocol = domElem.getAttribute(`data-baseProtocol`)
  const baseHost = domElem.getAttribute(`data-baseHost`)
  if (existingValue && baseProtocol && baseHost) {
    let value = `${baseProtocol}//${baseHost}${location.pathname}`

    console.log({
      hash: location.hash,
    })

    domElem.setAttribute(`href`, `${value}`)
  }
}